<template>
  <div>
    <a-modal v-model="visible" title="请选择定位点" @ok="handleOk">
        <el-amap-search-box
            class="search-box"
            :search-option="searchOption"
            :on-search-result="onSearchResult"
            ></el-amap-search-box>
      <el-amap
        ref="map"
        :vid="'amapDemo'"
        :center="center"
        :zoom="zoom"
        :events="events"
        class="amap-demo"
        style="height: 170px;">
        <el-amap-marker :position="position" >
    </el-amap-marker>
    </el-amap>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      center: [121.5273285, 31.21515044],
      zoom: 12,
      position: [121.5273285, 31.21515044],
      events: {
          init(o){
              console.log(o.getCenter());
          },
          zoomchange: (e) => {
              console.log(e);
          },
          zoomend: (e) => {
              //获取当前缩放zoom值
              console.log(this.$refs.map.$$getInstance().getZoom());
              console.log(e);
          },
          click: e => {
              const { lng, lat } = e.lnglat
              this.position = [lng, lat]
          }
      },
      searchOption: {
        city: '杭州',
        citylimit: false
      },
      address: ''
    };
  },
  methods: {
    showModal(position, item) {
      if (item !== null) {
        this.position = position
        this.center = position
      } else {
        this.position = [121.5273285, 31.21515044]
        this.center = [121.5273285, 31.21515044]
      }
      this.visible = true;
    },
    handleOk () {
        let valData = {
            address: this.address,
            position: this.position
        }
        this.$emit('ok', valData)
      this.visible = false;
    },
     // 高德地图
    onSearchResult(pois) {
      let latSum = 0
      let lngSum = 0
      if (pois.length > 0) {
        pois.forEach(poi => {
          let { lng, lat } = poi
          lngSum += lng
          latSum += lat
          this.position = [poi.lng, poi.lat]
        })
        let mapcenter = {
          lng: lngSum / pois.length,
          lat: latSum / pois.length
        }
        this.center = [mapcenter.lng, mapcenter.lat]
        this.address = pois[0].name
        this.position = [pois[0].lng, pois[0].lat]
      }
    },
  },
};
</script>
<style scoped>
.search-box {
  width: 100%;
  box-shadow: 0 0px 5px rgba(0,0,0,0.2);
  margin-bottom: 10px;
  border-radius: 5px;
  height: 45px;
}
</style>