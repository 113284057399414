<template>
    <div>
    <div style="margin-bottom:10px;">标签设置：<a-button icon="plus" @click="$refs.sysTagsModal.showModal(houseId)">选择标签</a-button></div>
    <a-tag v-for="(item, index) in tagList" :key="index">{{ item.name }}</a-tag>
    <div style="margin:10px 0;">房屋设施：</div>
    <a-checkbox-group v-model="facilChecked" style="width:100%;">
        <a-row>
        <a-col :span="4" v-for="(item, index) in facilList" :key="index">
            <a-checkbox :value="item.id">
                {{ item.name }}
            </a-checkbox>
        </a-col>
        
        </a-row>
    </a-checkbox-group>
    <div style="text-align: center;margin-top:20px;">
    <a-button @click="handleOk" type="primary">
        保存信息
    </a-button>
    </div>
    <SysTagsModal ref="sysTagsModal" @ok="findTags"/>
    </div>
</template>

<script>
import SysTagsModal from "./SysTagsModal"
export default {
  name: 'SecondHouse',
  components: {
      SysTagsModal
  },
  data () {
    return {
      tagList: [],
      facilList: [],
      facilChecked: []
    }
  },
  props: {
    houseId: {
      type: Number
    }
  },
  created(){
      this.findTags()
      this.findFacil()
      this.findChecked()
  },
  methods: {
    findTags () {
      this.$api.houseTag.list(this.houseId).then(res => {
        if (res.status === 0) {
            this.tagList = res.data
        } else {
            this.$message.warn(res.msg)
        }
        }).catch(err => {
        console.log(err)
        })
    },
    findFacil () {
      this.$api.sysFacil.list().then(res => {
        if (res.status === 0) {
            this.facilList = res.data
        } else {
            this.$message.warn(res.msg)
        }
        }).catch(err => {
        console.log(err)
        })
    },
    findChecked () {
        this.$api.houseFacil.checked(this.houseId).then(res => {
                if (res.status === 0) {
                    this.facilChecked = res.data
                } else {
                    this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err)
        }).finally(_ => {
            this.loading = false
        })
    },
    handleOk () {
        let valData = {
            houseId: this.houseId,
            facilIds: this.facilChecked
        }
        this.$api.houseFacil.save(valData).then(res => {
                if (res.status === 0) {
                    this.$message.success('保存成功')
                } else {
                    this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err)
        }).finally(_ => {
            this.loading = false
        })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
