<template>
  <div>
    <a-modal
      title="添加/编辑房产"
      width="800px"
      :visible="visible"
      :maskClosable="false"
      :destroyOnClose="true"
      @ok="handleOk"
      :footer="null"
      @cancel="handleCancel"
    >
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="基础信息">
          <a-form :form="form" class="form">
            <a-row :gutter="24" style="margin-bottom:10px;">
              <a-col :span="24">
                <a-form-item label="房产图片" :required="true" :labelCol="{
                  xs: { span: 24 },
                  sm: { span: 3 }
                }" :wrapperCol="{
                  xs: { span: 24 },
                  sm: { span: 21 }
                }">
                  <div v-for="(item, index) in imageList" :key="index" style="width:90px;float:left;margin-right:10px;border: 1px solid #ccc;text-align: center;">
                    <img style="width:80px;height:40px;border-radius:5px;" :src="item.image"/>
                    <span style="color:red;" v-if="item.image === image">封面</span>
                    <span v-else style="cursor: pointer;color:gray;" @click="updateImage(item.image)">设为封面</span>
                    <a-icon @click="delImage(index)" type="delete" />
                  </div>
                  <a-upload
                    name="file"
                    :action="imgurl"
                    :showUploadList="false"
                    :headers="{authorization: 'authorization-text'}"
                    @change="changeImage"
                  >
                    <a-button icon="upload" style="width:100%;">
                      图片上传</a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产名称">
                  <a-input placeholder="请输入房产名称" v-decorator="['name', {rules: [{required: true, message: '请输入房产名称'}]}]"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产类型">
                  <a-select
                    v-decorator="[
                      'type',
                      { rules: [{ required: true, message: '请选择房产类型!' }] },
                    ]"
                    @change="updateType"
                    placeholder="请选择房产类型"
                  >
                    <a-select-option :value="1">
                      新房
                    </a-select-option>
                    <a-select-option :value="2">
                      二手房
                    </a-select-option>
                    <a-select-option :value="3">
                      租房
                    </a-select-option>
                    <a-select-option :value="4">
                      商铺
                    </a-select-option>
                    <a-select-option :value="5">
                      写字楼
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="详细地址">
                  <a-input placeholder="请输入详细地址" v-decorator="['address', {rules: [{required: true, message: '请输入详细地址'}]}]"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产面积">
                  <a-input-number style="width:100%;" placeholder="请输入房产面积" v-decorator="['area', {rules: [{required: true, message: '请输入房产面积'}]}]"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="地区选择">
                  <a-cascader
                    :options="address"
                    v-decorator="['regionCode', {rules: [{ required: true, message: '请选择地区'}],initialValue: ['500000','500100','500191']}]"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item v-show="isShow" :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产户型">
                  <a-input-number style="width:22%;" :min="0" placeholder="几室" v-decorator="['room']"/> 室
                  <a-input-number style="width:22%;" :min="0" placeholder="几厅" v-decorator="['hall']"/> 厅
                  <a-input-number style="width:22%;" :min="0" placeholder="几卫" v-decorator="['guard']"/> 卫
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :required="true" :labelCol="labelCol" :wrapperCol="wrapperCol" label="地图定位">
                  <a-button icon="environment" @click="$refs.mapModal.showModal(position, item)" style="width:100%;">
                    选择定位地点
                  </a-button>
                  坐标点：{{ position.length === 0 ? '未选择定位地点' : position[0] + ',' + position[1]}}
                </a-form-item>
              </a-col>
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="房产金额">
                  <a-input style="width:100%;" placeholder="请输入房产金额" v-decorator="['money', {rules: [{required: true, message: '请输入房产金额'}]}]"/>
                </a-form-item>
              </a-col>
            </a-row>
             <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="出售方式">
                  <a-select
                    v-decorator="[
                      'mode',
                      { rules: [{ required: true, message: '房产出售方式!' }] },
                    ]"
                    placeholder="出售方式"
                  >
                    <a-select-option :value="1">
                      售卖
                    </a-select-option>
                    <a-select-option :value="2">
                      整租
                    </a-select-option>
                    <a-select-option :value="3">
                      办公
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="12">
               <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="楼层">
                  <a-input placeholder="请输入楼层" v-decorator="['storey']"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="挂牌时间">
                  <a-input placeholder="请输入挂牌时间" v-decorator="['listing']"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
               <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="朝向">
                  <a-input placeholder="请输入朝向" v-decorator="['orientation']"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="年代">
                  <a-input placeholder="请输入年代" v-decorator="['years']"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
               <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="电梯">
                  <a-input placeholder="请输入电梯" v-decorator="['elevator']"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="权属">
                  <a-input placeholder="请输入权属" v-decorator="['ownership']"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
               <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="楼型">
                  <a-input placeholder="请输入楼型" v-decorator="['buildingType']"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="用途">
                  <a-input placeholder="请输入用途" v-decorator="['purpose']"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
               <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="装修">
                  <a-input placeholder="请输入装修" v-decorator="['renovation']"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="12">
                <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="小区">
                  <a-input placeholder="请输入小区" v-decorator="['village']"/>
                </a-form-item>
              </a-col>
              <a-col :span="12">
               <a-form-item :labelCol="labelCol" :wrapperCol="wrapperCol" label="单价">
                  <a-input placeholder="请输入单价" v-decorator="['unitPrice']"/>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item :labelCol="{
                    xs: { span: 24 },
                    sm: { span: 3 }
                  }" :wrapperCol="{
                  xs: { span: 24 },
                  sm: { span: 20 }
                }" label="房源详情">
                    <a-textarea
                      v-model="des"
                      placeholder="请输入房源详情"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                </a-form-item>
              </a-col>
            </a-row>
            
          </a-form>
          <div style="text-align: center;">
            <a-button @click="handleOk" type="primary">
              提交信息
            </a-button>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="附加信息" :disabled="item === null">
         <SecondHouse :houseId="item.id" v-if="item !== null"/>
        </a-tab-pane>
      </a-tabs>
      
    </a-modal>
    <MapModal ref="mapModal" @ok="mapData"/>
  </div>
</template>
<script>
import MapModal from "@/components/MapModal"
import SecondHouse from "./SecondHouse"
import config from '@/http/config'
import moment from 'moment'
import store from '@/store'
import matchFun from '@/utils/matchFun'
import { mapState } from 'vuex'
import address from '@/utils/address'
import { getAddressFullCodeByCode } from '@/utils/addressUtil'
export default {
  components: {
    MapModal,
    SecondHouse
  },
  data () {
    return {
      visible: false,
      confirmLoading: false,
      matchFun: matchFun,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },
      item: null,
      address,
      des: '',
      imgurl: config.baseImgURL,
      imageList: [],
      image: '',
      isShow: true,
      position: [],
      form: this.$form.createForm(this)
    }
  },
  computed: {
    ...mapState({
    })
  },
  methods: {
    moment,
    showModal (item) {
      this.item = item
      this.image = ''
      this.imageList = []
      this.isShow = true
      if (item !== null) {
        this.isShow = item.type < 4
        setTimeout(() => {
          this.form.setFieldsValue({
            name: item.name,
            money: item.money,
            area: item.area,
            owner: item.owner,
            ownerMobile: item.ownerMobile,
            type: item.type,
            address: item.address,
            regionCode: getAddressFullCodeByCode(item.regionCode),
            room: item.room,
            hall: item.hall,
            guard: item.guard,
            mode: item.mode,
            unitPrice: item.unitPrice,
            storey: item.storey,
            listing: item.listing,
            orientation: item.orientation,
            years: item.years,
            elevator: item.elevator,
            ownership: item.ownership,
            buildingType: item.buildingType,
            purpose: item.purpose,
            renovation: item.renovation,
            village: item.village
          })
          this.position = [item.longitude, item.latitude]
          this.image = item.image
          this.des = item.des
          this.findList(this.item.id)
        })
      }
      this.visible = true
    },
    findList (houseId) {
        this.$api.houseImage.list(houseId).then(res => {
            if (res.status === 0) {
                res.data.forEach(element => {
                    this.imageList.push({
                      image: element.image
                    })
                });
                
            } else {
                this.$message.warn(res.msg)
            }
        }).catch(err => {
            console.log(err)
        })
    },
    delImage (index) {
      this.imageList.splice(index, 1)
    },
    updateImage (image) {
      this.image = image
    },
    mapData (e) {
      this.position = e.position
    },
    changeImage (info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.data.url
        }
        return file
      })
      if (info.file.status === 'done') {
        this.imageList.push({
          image: fileList[0].response.data.qiniuUrl
        })
        this.$message.success(`图片上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`图片上传失败`)
      }
    },
    handleOk (e) {
      console.log(222)
      if (this.image === '') {
        this.$message.warn('请选择封面！')
        return
      }
      if (this.position.length === 0) {
        this.$message.warn('请选择坐标点！')
        return
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          var upData = {...values}
          upData.id = this.item !== null ? this.item.id : null
          upData.longitude = this.position[0]
          upData.latitude = this.position[1]
          upData.image = this.image
          let image = {
            list: this.imageList
          }
          upData.imageList= image
          upData.provinceCode = values.regionCode[0]
          upData.cityCode = values.regionCode[1]
          upData.regionCode = values.regionCode[2]
          upData.des = this.des
          this.$api.house.save(upData).then(res => {
            if (res.status === 0) {
              this.$message.success('保存成功')
              this.handleCancel()
            } else {
              this.$message.warn(res.msg)
            }
          }).catch(err => {
            console.log(err)
          })
        }
      })
    },
    handleCancel (e) {
      this.visible = false
      this.$emit('ok')
    },
    updateType (e) {
      this.isShow = e < 4
      console.log(e)
    }
  }
}
</script>
<style scoped>

.search-box {
  width: 80%;
  box-shadow: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  height: 32px;
}

</style>
