<template>
  <div>
    <a-modal v-model="visible" title="选择标签" @ok="handleOk">
      <a-input style="width:300px;margin-right:20px;" v-model="name" placeholder="请输入标签名字" /><a-button @click="addTags" icon="plus">加入到标签库</a-button>
      <a-checkbox-group v-model="checkd" @change="onChange" style="margin-top:20px;width:100%;">
            <a-row>
            <a-col :span="8" v-for="(item, index) in data" :key="index">
                <a-checkbox :value="item.id">
                {{ item.name }}
                </a-checkbox>
            </a-col>
           
            </a-row>
        </a-checkbox-group>
    </a-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      visible: false,
      houseId: null,
      data: [],
      checkd: [],
      name: ''
    };
  },
  methods: {
    showModal (houseId) {
        console.log(houseId)
        this.houseId = houseId
        this.findList()
        this.findChecked()
        this.visible = true;
        
    },
    findList () {
        this.$api.sysTags.list().then(res => {
                if (res.status === 0) {
                    this.data = res.data
                } else {
                    this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err)
        }).finally(_ => {
            this.loading = false
        })
    },
    findChecked () {
        this.$api.houseTag.checked(this.houseId).then(res => {
                if (res.status === 0) {
                    this.checkd = res.data
                } else {
                    this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err)
        }).finally(_ => {
            this.loading = false
        })
    },
    onChange (e) {
        console.log(e)
    },
    addTags () {
        if (this.name === '') {
            this.$message.warn('请输入内容')
            return
        }
        let valData = {
            name: this.name
        }
        this.$api.sysTags.add(valData).then(res => {
                this.name = ''
                if (res.status === 0) {
                    this.findList()
                } else {
                    this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err)
        }).finally(_ => {
            this.loading = false
        })
    },
    handleOk(e) {
      console.log(e);
      let valData = {
          houseId: this.houseId,
          tags: this.checkd
      }
      this.$api.houseTag.save(valData).then(res => {
                if (res.status === 0) {
                    this.visible = false;
                    this.$emit('ok')
                } else {
                    this.$message.error(res.msg)
            }
        }).catch(err => {
            this.$message.error(err)
        }).finally(_ => {
            this.loading = false
        })
      
    },
  },
};
</script>