/*
 * @Author: Zhangfuxiang
 * @Description: 地址处理工具
 */
import address from '@/utils/address'
/**
 * 根据编码获取完整的code
 * @param {String} code 传入编码
 */
export function getAddressFullCodeByCode (code) {
  var arr = []
  arr.push(code.substring(0,2) + '0000')
  arr.push(code.substring(0,4) + '00')
  arr.push(code)
  return arr
}
/**
 * 根据最小级的code 获取完整的名称
 * @param {*} address
 * @param {*} addressName
 * @param {*} code
 * @param {*} level
 * @returns
 */
export function getAddressNameByCode (address, addressName, code, level) {
  let codeStr = level === 1 ? code.substring(0,2) + '0000' : (level === 2 ? code.substring(0,4) + '00' : code)
  address.forEach(element => {
    if (element.value === codeStr) {
      addressName.push(element.label)
      if (element.children !== undefined) {
        return getAddressNameByCode(element.children, addressName, code, level + 1)
      }
    }
  })
  return addressName
}
